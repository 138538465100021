
import { updateAppConfig } from '#app'
import { defuFn } from '/opt/build/repo/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "c53cae56-7a92-46a4-a45d-512e3ec01806"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/build/repo/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
